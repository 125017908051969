import React from 'react';
import styles from './footer.module.scss';

const Footer: React.FC = (): JSX.Element => {
  return (
    <footer className={styles.lFooter}>
      <ul className={styles.lFooterNav}>
        <li>
          <a
            href="https://www.surround.co.jp/"
            target="_blank"
            rel="noreferrer"
          >
            運営会社
          </a>
        </li>
        <li>
          <a
            href="https://www.surround.co.jp/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            プライバシーポリシー
          </a>
        </li>
        <li>
          <a href="/pdf/terms_of_service.pdf" target="_blank">
            利用規約
          </a>
        </li>
      </ul>
      <small className={styles.lFooterCopyright}>
        &copy; Surround All rights reserved.
      </small>
    </footer>
  );
};

export default Footer;
