import React from 'react';
import styles from './faq.module.scss';

const FAQ: React.FC = (): JSX.Element => {
  return (
    <section id="faq" className={styles.lFaq + ' ' + styles.section}>
      <h2 className={styles.headline}>よくあるご質問</h2>
      <dl className={styles.accordion}>
        <dt className={styles.accordionLabel}>
          <span>Q.</span>
          タダオキは本当に固定費がかからないの？
        </dt>
        <dd className={styles.accordionContent}>
          <span>A.</span>
          <p>
            月間出荷件数が200件に満たなかった場合のみ、基本料金をいただいております。
          </p>
        </dd>
        <dt className={styles.accordionLabel}>
          <span>Q.</span>商品はどれぐらいの量を置いておけるの？
        </dt>
        <dd className={styles.accordionContent}>
          <span>A.</span>
          <p>
            置いておく商品量が100ケース(3辺100cmサイズ想定)までは原則固定費¥0です。
            <br />
            ご利用当初から商品量が100ケースを超える場合、また、出荷量の多いお客さまには、個別に最適なプランを提案いたします。
            <br />
            なお、拠点への商品着荷から3ヶ月間発送作業が行われない商品については、お客さまに商品を返送いたします。
          </p>
        </dd>
        <dt className={styles.accordionLabel}>
          <span>Q.</span>
          売上が大きく成長した場合はどうなるの？
        </dt>
        <dd className={styles.accordionContent}>
          <span>A.</span>
          <p>
            お客さまのビジネスが成長し、出荷量が増え、さらに多くのスペース利用が必要になった場合、お客さまのマイページを通じて最適なプランへの移行をご提案いたします。{' '}
          </p>
        </dd>
        <dt className={styles.accordionLabel}>
          <span>Q.</span>
          梱包資材費込みの料金になっていますが、どんな梱包資材でも大丈夫ですか？{' '}
        </dt>
        <dd className={styles.accordionContent}>
          <span>A.</span>
          <p>
            タダオキ料金に含まれているのは、基本的なダンボール代（無地の茶箱）とクラフト紙の緩衝材費用です。
            <br />
            お客様の商品ロゴを入れたダンボールをご希望の場合や、プチプチ等の緩衝材をご希望の場合には追加費用が発生いたします（内容により決定）。
          </p>
        </dd>
      </dl>
    </section>
  );
};

export default FAQ;
