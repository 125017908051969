import React from 'react';
import styles from './recommend.module.scss';

const Recommend: React.FC = (): JSX.Element => {
  return (
    <section className={styles.lRecommend + ' ' + styles.section}>
      <h2 className={styles.headline}>
        タダオキは
        <br />
        こんな人におすすめです
      </h2>
      <ul className={styles.lRecommendUserVoice + ' ' + styles.clm3}>
        <li>
          <p>
            ECで商品を売り始めたけど
            <br />
            まだどれくらい売れるか分からない
          </p>
          <figure>
            <img src="/images/lp/uservoice01@2x.png" alt="" />
          </figure>
        </li>
        <li>
          <p>
            売れ始めてきたけど安定するまで
            <br />
            外注にかかる固定費を抑えたい
          </p>
          <figure>
            <img src="/images/lp/uservoice02@2x.png" alt="" />
          </figure>
        </li>
        <li>
          <p>
            製造工場からなるべく近い
            <br />
            物流倉庫から出荷したい
          </p>
          <figure>
            <img src="/images/lp/uservoice03@2x.png" alt="" />
          </figure>
        </li>
      </ul>
      <ul className={styles.clm2}>
        <li className={styles.lRecommendPerson + ' ' + styles.listCard}>
          <h3>こんな方に適しています</h3>
          <ul>
            <li>インターネットで商品販売を行っている方</li>
            <li>
              商品の在庫管理、一時保管、梱包・発送等の物流業務を外注している方
            </li>
            <li>物流外注費を見直したい方</li>
          </ul>
        </li>
        <li className={styles.lRecommendProduct + ' ' + styles.listCard}>
          <h3>こんな商品に適しています</h3>
          <ul>
            <li>化粧品・化粧水などのビューティープロダクツ</li>
            <li>健康食品やサプリメント</li>
            <li>日用雑貨全般</li>
            <li>家電雑貨全般</li>
            <li>アクセサリー</li>
            <li>常温管理可能な食品全般</li>
            <li>ワインなど酒類</li>
          </ul>
        </li>
      </ul>
    </section>
  );
};

export default Recommend;
