import React from 'react';
import styles from './how-to.module.scss';

const HowTo: React.FC = (): JSX.Element => {
  return (
    <section className={styles.lHowto + ' ' + styles.section}>
      <h2 className={styles.headline}>エントリー方法</h2>
      <ol className={styles.lHowtoStep + ' ' + styles.clm3}>
        <li>
          <figure>
            <img src="/images/lp/howto01@2x.png" alt="" />
          </figure>
          <h3>お客様情報を入力する</h3>
          <p>
            お客様のお名前、住所など必要な情報と、お支払い方法（クレジットカード）をご登録ください。
          </p>
        </li>
        <li>
          {/* TODO: 画像変更 */}
          <figure>
            <img src="/images/lp/howto02@2x.png" alt="" />
          </figure>
          <h3>商品・発送先情報を入力する</h3>
          <p>
            商品名、商品サイズ、月間出荷件数、商品量など商品と発送の基本的な情報を入力してください。
          </p>
        </li>
        <li>
          {/* TODO: 画像変更 */}
          <figure>
            <img src="/images/lp/howto03@2x.png" alt="" />
          </figure>
          <h3>スペースを選択する</h3>
          <p>
            商品・発送先を入力していただくと、自動でスペースの候補を表示します。お届け日を均一化できる複数拠点の選択も可能です。
          </p>
        </li>
      </ol>
      <p className={styles.note}>
        ※複数拠点での商品管理をご希望の方はお申し込み後のチャットよりご要望をお伝えください。
      </p>
    </section>
  );
};

export default HowTo;
