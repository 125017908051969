import { ProductCategory } from "@common/graphql/API";
import { UnexpectedError } from "@errors/UnexpectedError";
export var productCategoryToString = function(value) {
    switch(value){
        case ProductCategory.COSMETICS:
            return "化粧品";
        case ProductCategory.FOOD_AND_SUPPLEMENTS:
            return "食品・サプリメント";
        case ProductCategory.APPAREL_AND_GENERAL_GOODS:
            return "アパレル・雑貨";
        case ProductCategory.MEDICAL_EQUIPMENT:
            return "医療機器";
        case ProductCategory.OTHER:
            return "その他";
        default:
            throw new UnexpectedError({
                data: {
                    value: value
                }
            }, "予期せぬ商品カテゴリです");
    }
};
