import React from 'react';
import styles from './plan.module.scss';
import clsx from 'clsx';

const Plan: React.FC = (): JSX.Element => {
  return (
    <section id="js-l-plan" className={styles.lPlan + ' ' + styles.section}>
      <h2 className={styles.headline}>タダオキの料金プラン</h2>
      <p className={styles.leadSentence}>
        “タダオキ”は、販売量にかかわらず発生する固定費を
        <strong className={styles.underLine}>無料</strong>に。
        <br />
        お客さまそれぞれのこだわりに合わせて多数のスペースから最も最適な拠点を提案してくれるから相見積もりいらず。
        <br />
        とっても手軽にはじめられるサービスです。
      </p>
      <div className={styles.lPlanTable}>
        <div className={styles.lPlanTableInner}>
          <table className={styles.lPlanPrice}>
            <thead>
              <tr>
                <th />
                <td className={styles.basic}>
                  <span>
                    <svg
                      id="logo_yoko"
                      xmlns="http://www.w3.org/2000/svg"
                      width="75%"
                      height="50"
                      viewBox="0 0 170 50"
                    >
                      <g
                        id="グループ_112"
                        data-name="グループ 112"
                        transform="translate(67.928 7.496)"
                      >
                        <path
                          id="パス_197"
                          data-name="パス 197"
                          d="M624.894,318.636a.792.792,0,0,1,.783.939q-.079.409-.159.777-.166.768-.2.834h10.533a.793.793,0,0,1,.8.787v4.214a23.728,23.728,0,0,1-1.905,7.781,18.173,18.173,0,0,1-3.76,5.509,26.053,26.053,0,0,1-4.572,3.629,25.334,25.334,0,0,1-4.34,2.174,26.056,26.056,0,0,1-3.28,1.029l-.328.071a.793.793,0,0,1-.964-.77v-3.73a.785.785,0,0,1,.6-.763l.556-.137a21.023,21.023,0,0,0,2.866-1,20.03,20.03,0,0,0,3.628-2.011,15.407,15.407,0,0,0,3.479-3.351H623.3a.793.793,0,0,1-.8-.787v-3.3a.793.793,0,0,1,.8-.787h7.319a17.278,17.278,0,0,0,.63-3.694h-7.786a22.744,22.744,0,0,1-3.976,5.574,33.239,33.239,0,0,1-3.148,2.7l-.027.019a.8.8,0,0,1-1.266-.638v-4.456a.781.781,0,0,1,.287-.6q.176-.145.459-.385a11.526,11.526,0,0,0,1.656-1.88,17.992,17.992,0,0,0,1.822-3.187,18.791,18.791,0,0,0,1.248-3.926.8.8,0,0,1,.783-.635Z"
                          transform="translate(-615.049 -315.301)"
                        />
                        <path
                          id="パス_198"
                          data-name="パス 198"
                          d="M728.87,308.941a.792.792,0,0,1,.783.939q-.079.409-.158.777-.166.768-.2.834h10.533a.793.793,0,0,1,.8.787v4.215a23.72,23.72,0,0,1-1.9,7.781,18.173,18.173,0,0,1-3.76,5.508,26.059,26.059,0,0,1-4.572,3.629,25.325,25.325,0,0,1-4.34,2.174,26.08,26.08,0,0,1-3.28,1.029l-.328.071a.793.793,0,0,1-.964-.77v-3.73a.785.785,0,0,1,.6-.763l.556-.137a21.03,21.03,0,0,0,2.866-1,20.013,20.013,0,0,0,3.628-2.011,15.421,15.421,0,0,0,3.479-3.35h-5.332a.793.793,0,0,1-.8-.788v-3.3a.793.793,0,0,1,.8-.788H734.6a17.234,17.234,0,0,0,.629-3.694H727.44a22.745,22.745,0,0,1-3.976,5.574,33.23,33.23,0,0,1-3.147,2.7l-.027.019a.8.8,0,0,1-1.265-.638v-4.456a.782.782,0,0,1,.286-.6q.176-.145.459-.385a11.485,11.485,0,0,0,1.657-1.88,17.982,17.982,0,0,0,1.822-3.187,18.817,18.817,0,0,0,1.248-3.926.8.8,0,0,1,.783-.635Zm5.925.586v-3.133a.793.793,0,0,1,.8-.787h.69a.793.793,0,0,1,.8.787v3.133a.793.793,0,0,1-.8.787h-.69A.792.792,0,0,1,734.8,309.526Zm3.578,0v-3.133a.793.793,0,0,1,.8-.787h.69a.793.793,0,0,1,.8.787v3.133a.793.793,0,0,1-.8.787h-.69A.793.793,0,0,1,738.374,309.526Z"
                          transform="translate(-692.058 -305.606)"
                        />
                        <path
                          id="パス_199"
                          data-name="パス 199"
                          d="M834.835,345.637V332.17a25.35,25.35,0,0,1-3.893,6.359,32.2,32.2,0,0,1-4.274,4.217,24.3,24.3,0,0,1-3.346,2.321l-.166.089a.8.8,0,0,1-1.175-.694v-4.127a.779.779,0,0,1,.375-.667q.246-.151.652-.4a21.768,21.768,0,0,0,2.584-2.01,22.787,22.787,0,0,0,3.3-3.727,28.79,28.79,0,0,0,3.164-5.8h-9.274a.792.792,0,0,1-.8-.787V323.51a.792.792,0,0,1,.8-.787h12.057v-3.3a.793.793,0,0,1,.8-.787h3.573a.793.793,0,0,1,.8.787v3.3h2.979a.793.793,0,0,1,.8.787v3.427a.793.793,0,0,1-.8.787H840v17.912a.793.793,0,0,1-.8.787h-3.573A.793.793,0,0,1,834.835,345.637Z"
                          transform="translate(-768.31 -315.301)"
                        />
                        <path
                          id="パス_200"
                          data-name="パス 200"
                          d="M923.016,327.808V324.38a.793.793,0,0,1,.8-.787H930.8l-.242-3.607a.792.792,0,0,1,.8-.839h3.707a.793.793,0,0,1,.8.729l.277,3.717h8.28a.792.792,0,0,1,.8.787v3.427a.792.792,0,0,1-.8.787h-7.949l.3,4.381h7.651a.792.792,0,0,1,.8.787v3.558a.793.793,0,0,1-.8.788h-7.253l.537,7.592a.792.792,0,0,1-.8.842h-3.734a.793.793,0,0,1-.8-.732l-.545-7.7h-8.015a.793.793,0,0,1-.8-.788v-3.558a.793.793,0,0,1,.8-.787h7.65l-.3-4.381h-7.352A.793.793,0,0,1,923.016,327.808Z"
                          transform="translate(-843.142 -315.681)"
                        />
                      </g>
                      <g id="グループ_114" data-name="グループ 114">
                        <path
                          id="パス_201"
                          data-name="パス 201"
                          d="M386.992,280.568l-14.8,3.343-14.8,13.518L380.168,310.4v17.987l29.6-16.86V293.544Z"
                          transform="translate(-356.294 -279.479)"
                          fill="#fed900"
                        />
                        <path
                          id="パス_202"
                          data-name="パス 202"
                          d="M380.168,377.411,357.4,364.435V346.448l22.771,12.976Z"
                          transform="translate(-356.294 -328.499)"
                          fill="#d5ac00"
                        />
                        <path
                          id="パス_203"
                          data-name="パス 203"
                          d="M462.082,333.2v18.936l10.75-6.255V326.944Z"
                          transform="translate(-433.828 -313.987)"
                          fill="#fff"
                        />
                        <g id="グループ_113" data-name="グループ 113">
                          <path
                            id="パス_204"
                            data-name="パス 204"
                            d="M407.691,290.161c0-.017-.007-.034-.012-.05a1.088,1.088,0,0,0-.072-.2h0a1.075,1.075,0,0,0-.115-.181c-.011-.015-.022-.028-.034-.042a1.1,1.1,0,0,0-.155-.153v0l-.009-.005a1.094,1.094,0,0,0-.128-.086l-22.771-12.976a1.112,1.112,0,0,0-.8-.119l-14.8,3.343-.021.007c-.028.007-.055.016-.083.025s-.053.018-.079.028-.049.023-.073.035-.053.028-.079.043-.042.028-.063.043-.052.038-.076.059c-.009.008-.019.014-.028.022l-14.8,13.518-.016.017-.041.042c-.018.019-.035.038-.052.058l-.035.046c-.016.021-.03.043-.044.065s-.02.032-.029.048-.024.047-.036.071-.016.033-.023.05-.019.052-.027.079-.011.032-.015.049c-.008.032-.014.065-.02.1,0,.012,0,.023-.007.035a1.124,1.124,0,0,0-.009.137v17.99a1.085,1.085,0,0,0,.551.942l22.769,12.975h0a1.115,1.115,0,0,0,1.1,0l29.6-16.86a1.086,1.086,0,0,0,.551-.943V290.379h0A1.061,1.061,0,0,0,407.691,290.161Zm-24.024-11.6,19.965,11.377-5.189,1.172-5.191,1.173v-3.009a1.086,1.086,0,0,0-.556-.945,1.115,1.115,0,0,0-1.107.008l-3.474,2.021-16.088-9.168Zm7.379,12.618v16.409l-8.544,4.972v-16.41ZM369.2,282.09l16.735,9.537-5.094,2.964a1.085,1.085,0,0,0-.543.937v7.235l-3.43,3.134L356.095,294.06Zm-13.847,14.059,10.715,6.106,9.85,5.613v15.474l-20.565-11.719Zm22.771,27.193V307.715l2.175-1.987v8.735a1.086,1.086,0,0,0,.556.945,1.115,1.115,0,0,0,1.107-.008l10.75-6.256a1.085,1.085,0,0,0,.543-.937V294.514l5.963-1.347,6.3-1.422v15.993Z"
                            transform="translate(-353.145 -276.315)"
                          />
                          <path
                            id="パス_205"
                            data-name="パス 205"
                            d="M472.814,355.2a1.108,1.108,0,0,0,.559-.151l3.55-2.065a1.081,1.081,0,0,0,.39-1.49,1.111,1.111,0,0,0-1.51-.385l-3.55,2.065a1.081,1.081,0,0,0-.39,1.49A1.106,1.106,0,0,0,472.814,355.2Z"
                            transform="translate(-440.958 -331.858)"
                          />
                          <path
                            id="パス_206"
                            data-name="パス 206"
                            d="M475.8,368.023l-3.55,2.066a1.08,1.08,0,0,0-.39,1.49,1.112,1.112,0,0,0,1.51.384l3.55-2.066a1.08,1.08,0,0,0,.39-1.49A1.11,1.11,0,0,0,475.8,368.023Z"
                            transform="translate(-440.959 -344.44)"
                          />
                          <path
                            id="パス_207"
                            data-name="パス 207"
                            d="M475.8,384.934,472.253,387a1.08,1.08,0,0,0-.39,1.49,1.112,1.112,0,0,0,1.51.384l3.55-2.066a1.08,1.08,0,0,0,.39-1.49A1.11,1.11,0,0,0,475.8,384.934Z"
                            transform="translate(-440.959 -357.023)"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </td>
                <td className={styles.self}>
                  自分で
                  <br className={styles.deviceTab} />
                  コンビニ発送
                </td>
                <td>
                  一般的な
                  <br className={styles.deviceTab} />
                  物流センター
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  スペース料<span>（100ケースまで）</span>
                </th>
                <td className={clsx(styles.accent, styles.accentIndent)}>
                  <em>¥</em>
                  <strong>0</strong>※1
                </td>
                <td>
                  ¥0
                  <br />
                  <span className={styles.lPlanPriceNote}>
                    （自宅に保管スペースが必要）
                  </span>
                </td>
                <td>
                  ¥1,800-
                  <br className={styles.deviceTabL} />
                  ¥2,000<small>/パレット</small>
                </td>
              </tr>
              <tr>
                <th>
                  事務管理
                  <br className={styles.deviceSp} />
                  手数料
                </th>
                <td className={styles.accent}>
                  <em>¥</em>
                  <strong>0</strong>
                </td>
                <td>
                  ¥0
                  <br />
                  <span className={styles.lPlanPriceNote}>
                    （在庫管理・梱包・配送作業に時間がかかる）
                  </span>
                </td>
                <td>
                  ¥15,000-
                  <br className={styles.deviceTabL} />
                  ¥50,000<small>/月</small>
                </td>
              </tr>
              <tr>
                <th>
                  出荷作業費<span className={styles.deviceSp}>★</span>
                  <span className={styles.devicePc}>
                    （送料+作業料+梱包資材費 ※2）
                  </span>
                  <span className={styles.devicePc}>
                    60サイズの場合/1ケースあたり
                  </span>
                </th>
                <td className={styles.accent}>
                  <em>¥</em>
                  <strong className={styles.light}>750</strong>
                </td>
                <td>
                  ¥930
                  <br />
                  <span className={styles.lPlanPriceNote}>
                    （コンビニ配送の場合）
                  </span>
                </td>
                <td>
                  ¥650-
                  <br className={styles.deviceSp} />
                  ¥800<small>/ケース</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className={clsx(styles.deviceTab, styles.note)}>
        ★ 送料+入出庫作業料+梱包資材費 ※2。60サイズの場合/1ケースあたり。
      </p>
      <p className={styles.note}>
        ※1 月間出荷件数が200件以下の場合 、基本料金1万円が発生します。
      </p>
      <p className={styles.note}>
        ※2 梱包資材費には、ダンボール代と緩衝材費用を含みます。
      </p>
    </section>
  );
};

export default Plan;
