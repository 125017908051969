import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
export var CurrencyUnitStyle = {
    Global: "global",
    Local: "local"
};
export var formatPrice = function(price, param) {
    var _currencyUnitStyle = param.currencyUnitStyle, currencyUnitStyle = _currencyUnitStyle === void 0 ? CurrencyUnitStyle.Global : _currencyUnitStyle, _currencyUnit = param.currencyUnit, currencyUnit = _currencyUnit === void 0 ? "\xa5" : _currencyUnit, _asInt = param.asInt, asInt = _asInt === void 0 ? true : _asInt;
    var _price = asInt ? Math.floor(price) : price.toFixed(2);
    // `price.toLocaleString('ja-JP', {style: 'currency', currency: 'JPY'});` では
    // 負の数値を与えた場合に円マークがマイナスの後ろに付いてしまう（-¥1,000 のようになる）ため
    var _priceString = _price.toLocaleString("ja-JP");
    if (currencyUnitStyle === CurrencyUnitStyle.Global) {
        return "".concat(currencyUnit).concat(_priceString);
    }
    return "".concat(_priceString).concat(currencyUnit);
};
// 例）100円
export var formatPriceInJpyLocal = function(price, options) {
    return formatPrice(price, _object_spread_props(_object_spread({}, options), {
        currencyUnitStyle: CurrencyUnitStyle.Local,
        currencyUnit: "円"
    }));
};
// 例）¥100
export var formatPriceInJpyGlobal = function(price, options) {
    return formatPrice(price, _object_spread({}, options));
};
