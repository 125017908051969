import {BusinessStructureType, Prefecture} from '@common/graphql/API';

export type ProfileInputsForForm = {
  businessStructure: BusinessStructureType | null;
  username: string;
  postalCode: string;
  prefecture: Prefecture | '';
  city: string;
  addressLine: string;
  birthday: string;
  phoneNumber: string;
  email: string;
  password: string;
  passwordConfirm: string;
  privacyAgreement: boolean;
};

export const initialProfileInputs: ProfileInputsForForm = {
  businessStructure: null,
  username: '',
  postalCode: '',
  prefecture: '',
  city: '',
  addressLine: '',
  birthday: '',
  phoneNumber: '',
  email: '',
  password: '',
  passwordConfirm: '',
  privacyAgreement: false,
};
