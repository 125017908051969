import React from 'react';
import commonStyles from '@presentation/components/common/cssModules/common.module.scss';
import styles from './mv.module.scss';
import clsx from 'clsx';

const MV: React.FC = (): JSX.Element => {
  return (
    <section className={styles.lMv}>
      <div className={styles.inner}>
        <div className={styles.lMvText}>
          <div className={styles.lMvTextInner}>
            <h2 className={styles.lMvTagline}>
              EC事業者のための
              <br />
              固定費
              <strong>
                ¥<em>0</em>
              </strong>
              <br className={styles.deviceSp} />
              在庫仮置き・発送代行サービス
            </h2>
            <div className={styles.lMvLogo}>
              <img src="/images/lp/logo_tate.svg" alt="タダオキ" />
            </div>
          </div>
          <div className={styles.lMvCv}>
            <img
              className={styles.lMvCvBadge}
              src="/images/lp/mv_badge@2x.png"
              alt="業界初！固定費0円"
            />
            <a href="#js-l-about">
              <span>？</span>タダオキとは？
            </a>
          </div>
          <p className={styles.lMvTopic}>
            <strong>特許取得済み！</strong>
            <br />
            <span
              className={clsx(
                commonStyles.positionRelative,
                commonStyles.displayInlineBlock,
                commonStyles.textAlignCenter,
                commonStyles.paddingTop8,
                commonStyles.fontSize20,
              )}
            >
              <span className={clsx(commonStyles.underLineYellow)}>
                特許番号：特許7323223号
              </span>
            </span>
          </p>
        </div>
        <div className={styles.lMvImages}>
          <img src="/images/lp/mv_img01@2x.png" alt="" />
          <img src="/images/lp/mv_img02@2x.png" alt="" />
          <img src="/images/lp/mv_img03@2x.png" alt="" />
          {/* TODO: 画像差し替え */}
          <img src="/images/lp/mv_badge@2x.png" alt="業界初！固定費0円" />
        </div>
      </div>
    </section>
  );
};

export default MV;
