import React from 'react';
import styles from './about.module.scss';

const About: React.FC = (): JSX.Element => {
  return (
    <section id="js-l-about" className={styles.lAbout + ' ' + styles.section}>
      <div className={styles.lAboutTitle}>
        <img src="/images/lp/logo_yoko.svg" alt="タダオキ" />
        <span>ってなに？</span>
      </div>
      <div className={styles.lAboutText}>
        <h2 className={styles.headline}>
          固定費
          <strong>
            <span className={styles.headlineCurrencySymbol}>¥</span>
            <span className={styles.headlineCurrency}>0</span>
            <span className={styles.headlineReferenceMark}>※</span>
          </strong>
          の
          <br />
          発送代行サービス
        </h2>
        <p>
          “タダオキ”は、スモールECからパーソナルECまで多くのEC事業者に向け、
          <br className={styles.devicePc} />
          最適なロジスティクスサポートをご提供します。
        </p>
        <ul className={styles.lAboutFeature + ' ' + styles.clm3}>
          <li>
            <figure>
              <img src="/images/lp/feature01@2x.png" alt="" />
            </figure>
            最適な物流拠点を提案
          </li>
          <li>
            <figure>
              <img src="/images/lp/feature02@2x.png" alt="" />
            </figure>
            スペース料 ¥0
          </li>
          <li>
            <figure>
              <img src="/images/lp/feature03@2x.png" alt="" />
            </figure>
            管理費 ¥0
          </li>
        </ul>
        <p className={styles.note}>
          ※ 月額出荷数が一定以下の場合、基本料金１万円が発生します。
        </p>
        <div className={styles.lAboutEn}>About Tadaoki</div>
      </div>
    </section>
  );
};

export default About;
