import React from 'react';
import styles from './step.module.scss';

const Step: React.FC = (): JSX.Element => {
  return (
    <section id="js-l-step" className={styles.lStep + ' ' + styles.section}>
      <h2 className={styles.headline}>
        タダオキに商品を<strong>送るだけ</strong>で<br />
        あとは全部お任せ!
      </h2>
      <ol className={styles.lStepFlow + ' ' + styles.clm4}>
        <li>
          <figure>
            <img src="/images/lp/step01@2x.png" alt="" />
          </figure>
          指定された拠点に
          <br />
          商品を送る
        </li>
        <li>
          <figure>
            <img src="/images/lp/step02@2x.png" alt="" />
          </figure>
          ECサイトから
          <br />
          商品が売れるのを待つ
        </li>
        <li>
          <figure>
            <img src="/images/lp/step03@2x.png" alt="" />
          </figure>
          売れた分だけ
          <br />
          自動的に商品を発送※
        </li>
        <li>
          <figure>
            <img src="/images/lp/step04@2x.png" alt="" />
          </figure>
          毎月のお支払いが
          <br />
          クレジットカードにご請求
        </li>
      </ol>
      <p className={styles.note}>
        ※タダオキスタッフが受注データを管理しますので、発送指示は不要です。
      </p>
    </section>
  );
};

export default Step;
