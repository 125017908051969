import React, {useEffect, useState} from 'react';
import styles from './header.module.scss';
import {useRouter} from 'next/router';
import Link from 'next/link';
import clsx from 'clsx';
import {useAuthState} from '@presentation/components/auth/AuthState';
import {Auth} from 'aws-amplify';

const Header: React.FC = (): JSX.Element => {
  const router = useRouter();
  const {authState} = useAuthState();
  const [headerFixed, setHeaderFixed] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleScroll = () => {
    setHeaderFixed(window.scrollY !== 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={clsx(
        styles.lHeader,
        menuOpen ? styles.lHeaderActive : null,
        headerFixed ? styles.lHeaderFixed : null,
      )}
    >
      <h1
        className={clsx(
          styles.lHeaderLogo,
          menuOpen ? styles.lHeaderLogoActive : null,
        )}
      >
        <Link href="/">
          <a>
            <img src="/images/lp/logo_yoko.svg" alt="タダオキ" />
          </a>
        </Link>
      </h1>
      <nav className={clsx(styles.lGNav, menuOpen ? styles.lGNavActive : null)}>
        <ul className={clsx(styles.gnav, menuOpen ? styles.gnavActive : null)}>
          <li>
            <a href="#js-l-about">タダオキってなに？</a>
          </li>
          <li>
            <a href="#js-l-step">サービスについて</a>
          </li>
          <li>
            <a href="#js-l-plan">料金</a>
          </li>
          {authState.isSignedIn ? (
            <li>
              <Link href="/mypage/">
                <a>マイページ</a>
              </Link>
            </li>
          ) : (
            <li>
              <Link href="/signup/">
                <a>お申し込み</a>
              </Link>
            </li>
          )}
        </ul>
        <div
          className={clsx(
            styles.lGNavCv,
            menuOpen ? styles.lGNavActiveCv : null,
          )}
        >
          {authState.isSignedIn ? (
            <a
              onClick={async () => {
                await Auth.signOut();
              }}
            >
              ログアウト
            </a>
          ) : (
            <a
              onClick={async () => {
                await router.push('/signin/');
              }}
            >
              ログイン
            </a>
          )}
        </div>
      </nav>
      <button
        className={clsx(
          styles.lHeaderOpenCloseButton,
          menuOpen ? styles.lHeaderOpenCloseButtonActive : null,
        )}
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
      >
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </button>
    </header>
  );
};

export default Header;
