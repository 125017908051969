import React from 'react';
import styles from './apply.module.scss';
import {useRouter} from 'next/router';

const Apply: React.FC = (): JSX.Element => {
  const router = useRouter();
  return (
    <section id="js-l-apply" className={styles.lApply + ' ' + styles.section}>
      <button
        onClick={async () => {
          await router.push('/signup/');
        }}
      >
        タダオキの申込みに進む
      </button>
      <p className={styles.note}>
        冷凍・冷蔵など温度管理が必要な場合等は、お申し込み後のチャットよりご連絡ください。
      </p>
    </section>
  );
};

export default Apply;
