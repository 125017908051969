import { useEffect, useState } from "react";
import { UnexpectedError } from "@errors/UnexpectedError";
import * as Sentry from "@sentry/react";
export var useScrollToAnchor = function(router, to) {
    var ready = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
    var ref = useState(null), targetRef = ref[0], setTargetRef = ref[1];
    useEffect(function() {
        if (!router.isReady) return;
        if (router.asPath.includes("#")) {
            var anchorRegex = /#([^?&]+)/;
            var matches = router.asPath.match(anchorRegex);
            if (matches == null) {
                var error = new UnexpectedError({
                    data: {
                        router: router,
                        anchorRegex: anchorRegex,
                        matches: matches
                    }
                }, "アンカーが取得できませんでした");
                Sentry.captureException(error, {
                    extra: error.data
                });
                // エラー画面にするような事態ではないので何もしない
                return;
            }
            var anchorName = matches[1];
            var target = to.find(function(item) {
                return item.name === anchorName;
            });
            setTargetRef(target === null || target === void 0 ? void 0 : target.ref);
        }
    }, [
        router
    ]);
    useEffect(function() {
        if (targetRef == null || targetRef.current == null || !ready) return;
        targetRef.current.scrollIntoView();
    }, [
        targetRef === null || targetRef === void 0 ? void 0 : targetRef.current,
        ready
    ]);
};
