import {
  BasicShippingCostItem,
  ListBasicShippingCostsByStatusByCreatedAtQuery,
  ListBasicShippingCostsByStatusByCreatedAtQueryVariables,
} from '@common/graphql/API';

export type CustomListBasicShippingCostsByStatusByCreatedAtQueryVariables =
  ListBasicShippingCostsByStatusByCreatedAtQueryVariables;

export type CustomListBasicShippingCostsByStatusByCreatedAtQuery =
  ListBasicShippingCostsByStatusByCreatedAtQuery & {
    listBasicShippingCostsByStatusByCreatedAt?: {
      items: Array<{
        __typename: 'BasicShippingCost';
        nekoposu: BasicShippingCostItem;
        size60: BasicShippingCostItem;
        size80: BasicShippingCostItem;
        size100: BasicShippingCostItem;
        size140: BasicShippingCostItem;
        size160: BasicShippingCostItem;
      } | null>;
    } | null;
  };

export const customListBasicShippingCostsByStatusByCreatedAt = /* GraphQL */ `
  query ListBasicShippingCostsByStatusByCreatedAt(
    $status: BasicShippingCostStatus!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBasicShippingCostFilterInput
    $limit: Int
  ) {
    listBasicShippingCostsByStatusByCreatedAt(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
    ) {
      items {
        nekoposu {
          basic
        }
        size100 {
          basic
        }
        size140 {
          basic
        }
        size160 {
          basic
        }
        size60 {
          basic
        }
        size80 {
          basic
        }
      }
    }
  }
`;
