import Footer from '../components/lp/footer/Footer';
import FAQ from '../components/lp/faq/FAQ';
import HowTo from '../components/lp/how-to/HowTo';
import Recommend from '../components/lp/recommend/Recommend';
import Plan from '../components/lp/plan/Plan';
import Step from '../components/lp/step/Step';
import About from '../components/lp/about/About';
import MV from '../components/lp/mv/MV';
import Header from '../components/lp/header/Header';
import styles from '../components/lp/styles/global.module.scss';
import React, {useRef, useState} from 'react';
import More from '../components/lp/more/More';
import Apply from '../components/lp/apply/Apply';
import ModelCase from '../components/lp/model-case/ModelCase';
import Compare from '../components/lp/compare/Compare';
import Simulator from '../components/lp/simulator/Simulator';
import Layout from '../components/lp/layout/Layout';
import {useRouterChange} from '@presentation/hooks/router/useRouterChange';
import {SeoProps} from '../components/common/seo/SeoTags';
import {GetServerSideProps} from 'next';
import {useRouter} from 'next/router';
import {useScrollToAnchor} from '@presentation/hooks/scroll/useScrollToAnchor';

type Props = {
  seo: SeoProps;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const {req} = context;
  return {
    props: {
      seo: {
        description:
          'まずは、簡単見積！“タダオキ”はEC事業者に向け、最適なロジスティクスサポートをご提供します。',
        pathName: req.url,
      },
    },
  };
};

const Index = ({seo}: Props): JSX.Element => {
  const router = useRouter();
  useRouterChange();

  const [simulatorOpen, setSimulatorOpen] = useState<boolean>(false);

  const openSimulator = () => {
    setSimulatorOpen(true);
  };
  const closeSimulator = () => {
    setSimulatorOpen(false);
  };

  const faqRef = useRef<HTMLDivElement>(null);
  useScrollToAnchor(router, [
    {
      name: 'faq',
      ref: faqRef,
    },
  ]);

  return (
    <Layout seo={seo}>
      <div className={styles.lpWrapper}>
        <div id="js-l-wrapper" className="l-wrapper">
          <Header />
          <div className="l-contents">
            <MV />
            <About />
            <Step />
            <Compare />
            <Recommend />
            <Plan />
            <ModelCase
              onSimulatorClick={() => {
                openSimulator();
              }}
            />
            <More />
            <HowTo />
            <Apply />
            <div ref={faqRef}>
              <FAQ />
            </div>
          </div>
          <Footer />
          <Simulator
            visible={simulatorOpen}
            onClose={() => {
              closeSimulator();
            }}
          />
        </div>
        {/* _script >>  */}
        {/* <% include parts/_script%> */}
        {/* << _l-footer */}
      </div>
    </Layout>
  );
};

export default Index;
