import React, {useState} from 'react';
import styles from './simulator.module.scss';
import clsx from 'clsx';
import {useRouter} from 'next/router';
import {ObjectShape} from 'yup/lib/object';
import {y} from '@common/form/locale/ja_JP';
import {
  BasicShippingCost,
  BasicShippingCostItem,
  BasicShippingCostStatus,
  ModelSortDirection,
  ProductCategory,
  ShippingPackageSize,
  ShippingPercentageInput,
  ShippingPercentageRegion,
} from '@common/graphql/API';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {defaultValues, FormInputs} from './inputs';
import {initialSingUpState, signUpState, Steps} from '../../signup/signUpState';
import {useScreenState} from '@presentation/components/screen/screenStateHandler/screenState';
import {useSetRecoilState} from 'recoil';
import {FormControl, FormHelperText, TextField} from '@mui/material';
import {productCategoryToString} from '@constants/shipping/productCategory';
import {UnexpectedError} from '@errors/UnexpectedError';
import {packageSizeToString} from '@constants/shipping/shippingPackageSize';
import {formatPriceInJpyGlobal} from '@common/format/price/formatPrice';
import {API} from 'aws-amplify';
import {GRAPHQL_AUTH_MODE, GraphQLResult} from '@aws-amplify/api-graphql';
import {
  customListBasicShippingCostsByStatusByCreatedAt,
  CustomListBasicShippingCostsByStatusByCreatedAtQuery,
  CustomListBasicShippingCostsByStatusByCreatedAtQueryVariables,
} from './queries';
import {signUpStateSessionStorageKey} from '../../../constants/platform/sessionStorage/keys';
import {useAuthState} from '@presentation/components/auth/AuthState';
import {sendEvent} from '@common/google/tag_manager/sentEvent';
import {SIMULATE_BUTTON} from '@common/google/analytics/custom_events';

const getValidationRules = () => {
  const basicRules: ObjectShape = {
    productCategory: y
      .array<ProductCategory[]>()
      .required()
      .min(1)
      .label('商品カテゴリ'),
    shippingQuantity: y.number().required().integer().min(1).label('出荷件数'),
    shippingPercentages: y
      .mixed<
        (
          | ShippingPercentageInput
          | {region: ShippingPercentageRegion; percentage: null}
        )[]
      >()
      .required()
      .test('isNotEmpty', '${path}を入力してください', (input) => {
        if (input == null) {
          return false;
        }
        const empties = input.filter((item) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // 初期値が空文字列のため
          return item.percentage === '';
        });
        return empties.length !== input.length;
      })
      .test('isInt', '${path}は整数で入力してください', (input) => {
        if (input == null) {
          return false;
        }
        const notIntegers = input.filter((item) => {
          // 入力欄から得られる値は文字列のため
          const number = Number(item.percentage);
          return !Number.isInteger(number);
        });
        return notIntegers.length === 0;
      })
      .test(
        'totalEquals100',
        '${path}は合計で100%になるように入力してください',
        (input) => {
          if (input == null) {
            return false;
          }
          const total = input.reduce((prev, item) => {
            // 入力欄から得られる値は文字列のため
            return prev + Number(item.percentage);
          }, 0);
          return total === 100;
        },
      )
      .label('配送サイズの割合'),
  };

  return y.object().shape({
    ...basicRules,
  });
};

type Props = {
  visible: boolean;
  onClose: () => void;
};

const Simulator: React.FC<Props> = ({visible, onClose}: Props): JSX.Element => {
  const router = useRouter();
  const {authState} = useAuthState();
  const {showLoading, hideLoading, makeErrorScreen} = useScreenState();
  const [simulatorInputs, setSimulatorInputs] = useState<FormInputs>();
  const [resultPrice, setResultPrice] = useState<number | null>(null);
  const setSignUpState = useSetRecoilState(signUpState);
  const formMethods = useForm<FormInputs>({
    resolver: yupResolver(getValidationRules()),
    defaultValues,
  });
  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: {errors},
  } = formMethods;

  const watchShippingPercentages = watch('shippingPercentages');

  const onSubmit: SubmitHandler<FormInputs> = async (inputs) => {
    try {
      showLoading();

      if (inputs.shippingQuantity === '') {
        throw new UnexpectedError(
          {
            data: {
              inputs,
            },
          },
          '出荷件数がありません',
        );
      }

      const {data, errors} = (await API.graphql({
        query: customListBasicShippingCostsByStatusByCreatedAt,
        variables: {
          status: BasicShippingCostStatus.AVAILABLE,
          sortDirection: ModelSortDirection.DESC,
          limit: 1,
        } as CustomListBasicShippingCostsByStatusByCreatedAtQueryVariables,
        authMode: authState.isSignedIn
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      })) as GraphQLResult<CustomListBasicShippingCostsByStatusByCreatedAtQuery>;

      const _basicShippingCosts =
        data?.listBasicShippingCostsByStatusByCreatedAt?.items;

      if (
        _basicShippingCosts == null ||
        _basicShippingCosts[0] == null ||
        errors
      ) {
        throw new UnexpectedError(
          {
            data: {
              data,
              errors,
            },
          },
          '基本配送料の取得に失敗しました',
        );
      }

      const latestBasicShippingCosts = _basicShippingCosts[0];
      const totalPrice = inputs.shippingPercentages.reduce((prev, current) => {
        if (current.percentage === '' || current.percentage === 0) return prev;

        const size =
          current.size.toLocaleLowerCase() as keyof BasicShippingCost;
        // 基本料金の方で計算する仕様のため
        const cost = (latestBasicShippingCosts[size] as BasicShippingCostItem)
          .basic;
        const price =
          cost *
          ((inputs.shippingQuantity as number) * (current.percentage / 100));

        return prev + price;
      }, 0);

      setResultPrice(totalPrice);
      setSimulatorInputs(inputs);

      sendEvent(SIMULATE_BUTTON, {
        simulate_result: totalPrice,
      });
    } catch (error) {
      makeErrorScreen(
        new UnexpectedError(
          {
            data: {
              inputs,
              error,
            },
          },
          'シミュレータの計算に失敗しました',
        ),
      );
    } finally {
      hideLoading();
    }
  };

  const restResult = (): void => {
    setResultPrice(null);
  };

  return (
    <section
      id="js-l-entry"
      className={clsx(styles.lSimulator, visible ? styles.visible : null)}
    >
      <CloseBtnGroup onClose={onClose} />
      <div className={styles.lSimulatorDialog}>
        <div className={styles.lSimulatorDialogContents}>
          <h2 className={styles.headline}>料金シミュレーター</h2>
          <p className={styles.note}>
            以下のフォームに入力していただくと、概算費用を表示します。
            <br />
            （すべて必須項目です）
          </p>
          <div className={styles.lSimulatorDialogEstimate}>
            <FormProvider {...formMethods}>
              <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.lSimulatorDialogEstimateForm}>
                  <dl className={styles.form}>
                    <dt className={styles.formLabel}>商品カテゴリ</dt>
                    <dd className={styles.formContent}>
                      <FormControl error={Boolean(errors.productCategory)}>
                        <Controller
                          control={control}
                          name="productCategory"
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          render={({field}) => {
                            const checkboxes: JSX.Element[] = [];
                            Object.values(ProductCategory).map((s, i) => {
                              checkboxes.push(
                                <li key={i}>
                                  <input
                                    {...field}
                                    type="checkbox"
                                    id={`productCategory${i}`}
                                    value={s}
                                    onChange={() => {
                                      restResult();

                                      let newValue: ProductCategory[];
                                      if (field.value) {
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        if (!field.value.includes(s)) {
                                          newValue = [...field.value, s];
                                        } else {
                                          newValue = field.value.filter(
                                            (v) => v !== s,
                                          );
                                        }
                                        field.onChange(newValue ?? []);
                                      }
                                    }}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    checked={field.value?.includes(s)}
                                  />
                                  <label
                                    htmlFor={`productCategory${i}`}
                                    className={styles.formCheckbox}
                                  >
                                    {productCategoryToString(s)}
                                  </label>
                                </li>,
                              );
                            });
                            return <ul>{checkboxes}</ul>;
                          }}
                        />
                        <FormHelperText>
                          {errors.productCategory &&
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errors.productCategory?.message}
                        </FormHelperText>
                      </FormControl>
                    </dd>
                    <dt className={styles.formLabel}>出荷件数</dt>
                    <dd
                      className={clsx(
                        styles.formContent,
                        styles.formContentShippingQuantity,
                      )}
                    >
                      <Controller
                        control={control}
                        name="shippingQuantity"
                        render={({field}) => (
                          <>
                            <TextField
                              {...field}
                              variant="outlined"
                              required
                              fullWidth
                              placeholder="ex. 50"
                              error={Boolean(errors.shippingQuantity)}
                              helperText={
                                errors.shippingQuantity &&
                                errors.shippingQuantity?.message
                              }
                              onChange={(event) => {
                                restResult();
                                setValue(
                                  'shippingQuantity',
                                  event.currentTarget
                                    .value as unknown as number,
                                );
                              }}
                              className={styles.small}
                            />
                            <p
                              className={styles.formContentShippingQuantityUnit}
                            >
                              件/月
                            </p>
                          </>
                        )}
                      />
                    </dd>
                    <dt className={styles.formLabel}>
                      <p>配送サイズの割合</p>
                      <span>（合計で100にしてください）</span>
                    </dt>
                    <dd className={styles.formContent}>
                      <FormControl error={Boolean(errors.shippingPercentages)}>
                        <ul className={styles.place}>
                          {watchShippingPercentages.map((entry, index) => {
                            const _size =
                              entry.size as unknown as ShippingPackageSize;
                            const _percentage = entry.percentage;
                            return (
                              <Controller
                                key={index}
                                control={control}
                                name="shippingPercentages"
                                render={({field}) => (
                                  <li>
                                    <p>
                                      {packageSizeToString(
                                        _size as unknown as ShippingPackageSize,
                                      )}
                                    </p>
                                    <TextField
                                      {...field}
                                      value={_percentage}
                                      variant="outlined"
                                      required
                                      fullWidth
                                      inputProps={{
                                        maxLength: 3,
                                      }}
                                      placeholder="ex.25"
                                      onChange={(e) => {
                                        restResult();

                                        const newShippingPercentages =
                                          watchShippingPercentages.map((p) => {
                                            if (p.size === _size) {
                                              const _number = Number(
                                                e.currentTarget.value,
                                              );

                                              return {
                                                size: p.size,
                                                percentage: !isNaN(_number)
                                                  ? _number
                                                  : e.currentTarget.value,
                                              };
                                            }
                                            return p;
                                          });

                                        setValue(
                                          'shippingPercentages',
                                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                          // @ts-ignore
                                          newShippingPercentages,
                                          {
                                            shouldValidate: true,
                                          },
                                        );
                                      }}
                                    />
                                    <p
                                      className={
                                        styles.formContentShippingPercentagesUnit
                                      }
                                    >
                                      %
                                    </p>
                                  </li>
                                )}
                              />
                            );
                          })}
                        </ul>
                        <FormHelperText>
                          {errors.shippingPercentages &&
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            errors.shippingPercentages?.message}
                        </FormHelperText>
                      </FormControl>
                    </dd>
                  </dl>
                </div>
                {resultPrice != null ? (
                  <ResultGroup
                    price={resultPrice}
                    onClick={async () => {
                      showLoading();
                      if (!simulatorInputs) {
                        throw new UnexpectedError(
                          {
                            data: {
                              simulatorInputs,
                            },
                          },
                          'シミュレーションデータがありません',
                        );
                      }
                      const newState = {
                        ...initialSingUpState,
                        step: Steps.Profile,
                        inputs: {
                          ...initialSingUpState.inputs,
                          shippingProducts: {
                            ...initialSingUpState.inputs.shippingProducts,
                            productCategory: simulatorInputs.productCategory,
                            shippingQuantity:
                              simulatorInputs.shippingQuantity as number,
                          },
                        },
                        storedAt: new Date(),
                      };

                      sessionStorage.setItem(
                        signUpStateSessionStorageKey,
                        JSON.stringify(newState),
                      );

                      setSignUpState(newState);
                      await router.push('/signup/');
                    }}
                  />
                ) : (
                  <EstimateBtnGroup />
                )}
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </section>
  );
};

type CloseBtnGroupProps = {
  onClose: () => void;
};

const CloseBtnGroup: React.FC<CloseBtnGroupProps> = ({
  onClose,
}: CloseBtnGroupProps): JSX.Element => {
  return (
    <div className={styles.lSimulatorClose}>
      <a onClick={onClose}>
        <img src="/images/lp/close.svg" />
      </a>
    </div>
  );
};

type ResultGroupProps = {
  price: number;
  onClick: () => Promise<void>;
};

const ResultGroup: React.FC<ResultGroupProps> = ({
  price,
  onClick,
}: ResultGroupProps): JSX.Element => {
  return (
    <div className={styles.lSimulatorDialogResult}>
      <div className={styles.lSimulatorDialogResultPrice}>
        ご入力いただいた内容では
        <div className={styles.lSimulatorDialogResultPriceNum}>
          <strong>{formatPriceInJpyGlobal(price)}~</strong>/月
        </div>
        くらいかかります
      </div>
      <div
        className={styles.formBtn + ' ' + styles.lSimulatorDialogEstimateBtn}
      >
        <button type="button" onClick={onClick}>
          タダオキの申込みに進む
        </button>
      </div>
      <p className={styles.lSimulatorDialogResultNote}>
        ※生鮮食品、医療機器、危険物に該当するもの等お預かりできないものもございます。
        <br />
        詳しくはお申し込みより拠点担当者にお問い合わせください。
      </p>
    </div>
  );
};

const EstimateBtnGroup: React.FC = (): JSX.Element => {
  return (
    <div className={styles.formBtn + ' ' + styles.lSimulatorDialogEstimateBtn}>
      <button type="submit">
        <span>¥</span>
        概算費用を表示する
      </button>
    </div>
  );
};

export default Simulator;
