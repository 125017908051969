import React from 'react';
import styles from './model-case.module.scss';

type Props = {
  onSimulatorClick: () => void;
};

const ModelCase: React.FC<Props> = ({onSimulatorClick}: Props): JSX.Element => {
  return (
    <section
      id="js-l-step"
      className={styles.lModelCase + ' ' + styles.section}
    >
      <h2 className={styles.headline}>タダオキの活用事例</h2>
      <p className={styles.lead}>
        “タダオキ”は商品、サイズ、出荷量、配送先によって料金が変動します。
        <br />
        いくつかモデルケースをご紹介しますので、ご参考ください。
      </p>
      <ol className={styles.lModelCaseFlow}>
        <ModelCaseListCard
          title="オリジナルT-シャツ、パーカーなどを取り扱うアパレルECショップ"
          product="アパレル"
          location="埼玉県"
          size="60サイズ"
          shipping="関東"
          shipment="200件"
          formula="60サイズ(¥750) × 200件"
          price="¥150,000"
          storageLocation="三郷拠点"
        />
        <ModelCaseListCard
          title="地元特産品を使用した、長期保存可能なドライフードの食品"
          product="食品"
          location="秋田県"
          size="ネコポス"
          shipping="東北、関西"
          shipment="150件"
          formula="ネコポス(¥400) × 150件 + ¥10,000"
          price="¥70,000"
          storageLocation="青森拠点・柏拠点の2拠点お預け"
        />
        <ModelCaseListCard
          title="一点物の手作り伝統工芸品をメインとする木彫り細工のECショップ"
          product="陶器"
          location="佐賀"
          size="80サイズ"
          shipping="日本全国"
          shipment="20件"
          formula="80サイズ(¥850) × 20件 + ¥10,000"
          price="¥27,000"
          storageLocation="三郷拠点"
        />
      </ol>
      <div className={styles.lModelCaseSimulator}>
        <p>
          商品情報、配送先情報を入力すると、料金をシミュレーションすることができます。
          <br />
          出荷件数、配送サイズに変動がある場合の目安としてお使い頂けます。
        </p>
        <button onClick={onSimulatorClick}>料金シミュレーター</button>
      </div>
    </section>
  );
};

type ModelCaseListCardProps = {
  title: string;
  product: string;
  location: string;
  size: string;
  shipping: string;
  shipment: string;
  formula: string;
  price: string;
  storageLocation: string;
};

const ModelCaseListCard = ({
  title,
  product,
  location,
  size,
  shipping,
  shipment,
  formula,
  price,
  storageLocation,
}: ModelCaseListCardProps): JSX.Element => {
  return (
    <li className={styles.listCard}>
      <h3>{title}</h3>
      <div className={styles.lModelCaseFlowTableWrapper}>
        <table>
          <tbody>
            <tr>
              <td className={styles.label}>商品:</td>
              <td className={styles.value}>{product}</td>
            </tr>
            <tr>
              <td className={styles.label}>製造場所:</td>
              <td className={styles.value}>{location}</td>
            </tr>
            <tr>
              <td className={styles.label}>主要サイズ:</td>
              <td className={styles.value}>{size}</td>
            </tr>
            <tr>
              <td className={styles.label}>主な配送先:</td>
              <td className={styles.value}>{shipping}</td>
            </tr>
            <tr>
              <td className={styles.label}>月の出荷量:</td>
              <td className={styles.value}>{shipment}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={styles.lModelCaseFlowSummary}>
        <p className={styles.formula}>{formula}</p>
        <p className={styles.price}>
          送料込み<span>{price}</span>/月
        </p>
        <p className={styles.storageLocation}>{storageLocation}</p>
      </div>
    </li>
  );
};

export default ModelCase;
