import { ShippingPackageSize } from "@common/graphql/API";
import { UnexpectedError } from "@errors/UnexpectedError";
export var packageSizeToString = function(value) {
    switch(value){
        case ShippingPackageSize.NEKOPOSU:
            return "ネコポス";
        case ShippingPackageSize.SIZE60:
            return "60サイズ";
        case ShippingPackageSize.SIZE80:
            return "80サイズ";
        case ShippingPackageSize.SIZE100:
            return "100サイズ";
        case ShippingPackageSize.SIZE140:
            return "140サイズ";
        case ShippingPackageSize.SIZE160:
            return "160サイズ";
        default:
            throw new UnexpectedError({
                data: {
                    value: value
                }
            }, "予期せぬ配送サイズです");
    }
};
