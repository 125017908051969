/* tslint:disable */ /* eslint-disable */ //  This file was automatically generated and should not be edited.
export var InvoiceStatus;
(function(InvoiceStatus) {
    InvoiceStatus["DRAFT"] = "DRAFT";
    InvoiceStatus["OPEN"] = "OPEN";
    InvoiceStatus["PAID"] = "PAID";
})(InvoiceStatus || (InvoiceStatus = {}));
export var ModelAttributeTypes;
(function(ModelAttributeTypes) {
    ModelAttributeTypes["binary"] = "binary";
    ModelAttributeTypes["binarySet"] = "binarySet";
    ModelAttributeTypes["bool"] = "bool";
    ModelAttributeTypes["list"] = "list";
    ModelAttributeTypes["map"] = "map";
    ModelAttributeTypes["number"] = "number";
    ModelAttributeTypes["numberSet"] = "numberSet";
    ModelAttributeTypes["string"] = "string";
    ModelAttributeTypes["stringSet"] = "stringSet";
    ModelAttributeTypes["_null"] = "_null";
})(ModelAttributeTypes || (ModelAttributeTypes = {}));
export var BasicShippingCostStatus;
(function(BasicShippingCostStatus) {
    BasicShippingCostStatus["AVAILABLE"] = "AVAILABLE";
    BasicShippingCostStatus["UNAVAILABLE"] = "UNAVAILABLE";
})(BasicShippingCostStatus || (BasicShippingCostStatus = {}));
export var ProductCategory;
(function(ProductCategory) {
    ProductCategory["COSMETICS"] = "COSMETICS";
    ProductCategory["FOOD_AND_SUPPLEMENTS"] = "FOOD_AND_SUPPLEMENTS";
    ProductCategory["APPAREL_AND_GENERAL_GOODS"] = "APPAREL_AND_GENERAL_GOODS";
    ProductCategory["MEDICAL_EQUIPMENT"] = "MEDICAL_EQUIPMENT";
    ProductCategory["OTHER"] = "OTHER";
})(ProductCategory || (ProductCategory = {}));
export var StorageAreaSizeUnit;
(function(StorageAreaSizeUnit) {
    StorageAreaSizeUnit["TSUBO"] = "TSUBO";
    StorageAreaSizeUnit["PALLET"] = "PALLET";
    StorageAreaSizeUnit["CASE"] = "CASE";
})(StorageAreaSizeUnit || (StorageAreaSizeUnit = {}));
export var ShippingPackageSize;
(function(ShippingPackageSize) {
    ShippingPackageSize["NEKOPOSU"] = "NEKOPOSU";
    ShippingPackageSize["SIZE60"] = "SIZE60";
    ShippingPackageSize["SIZE80"] = "SIZE80";
    ShippingPackageSize["SIZE100"] = "SIZE100";
    ShippingPackageSize["SIZE140"] = "SIZE140";
    ShippingPackageSize["SIZE160"] = "SIZE160";
})(ShippingPackageSize || (ShippingPackageSize = {}));
export var ShippingPercentageRegion;
(function(ShippingPercentageRegion) {
    ShippingPercentageRegion["KANTO"] = "KANTO";
    ShippingPercentageRegion["KANSAI"] = "KANSAI";
    ShippingPercentageRegion["OKINAWA"] = "OKINAWA";
    ShippingPercentageRegion["OTHER"] = "OTHER";
})(ShippingPercentageRegion || (ShippingPercentageRegion = {}));
export var Prefecture;
(function(Prefecture) {
    Prefecture["HOKKAIDO"] = "HOKKAIDO";
    Prefecture["AOMORI"] = "AOMORI";
    Prefecture["IWATE"] = "IWATE";
    Prefecture["MIYAGI"] = "MIYAGI";
    Prefecture["AKITA"] = "AKITA";
    Prefecture["YAMAGATA"] = "YAMAGATA";
    Prefecture["FUKUSHIMA"] = "FUKUSHIMA";
    Prefecture["IBARAKI"] = "IBARAKI";
    Prefecture["TOCHIGI"] = "TOCHIGI";
    Prefecture["GUNMA"] = "GUNMA";
    Prefecture["SAITAMA"] = "SAITAMA";
    Prefecture["CHIBA"] = "CHIBA";
    Prefecture["TOKYO"] = "TOKYO";
    Prefecture["KANAGAWA"] = "KANAGAWA";
    Prefecture["NIIGATA"] = "NIIGATA";
    Prefecture["TOYAMA"] = "TOYAMA";
    Prefecture["ISHIKAWA"] = "ISHIKAWA";
    Prefecture["FUKUI"] = "FUKUI";
    Prefecture["YAMANASHI"] = "YAMANASHI";
    Prefecture["NAGANO"] = "NAGANO";
    Prefecture["GIFU"] = "GIFU";
    Prefecture["SHIZUOKA"] = "SHIZUOKA";
    Prefecture["AICHI"] = "AICHI";
    Prefecture["MIE"] = "MIE";
    Prefecture["SHIGA"] = "SHIGA";
    Prefecture["KYOTO"] = "KYOTO";
    Prefecture["OSAKA"] = "OSAKA";
    Prefecture["HYOGO"] = "HYOGO";
    Prefecture["NARA"] = "NARA";
    Prefecture["WAKAYAMA"] = "WAKAYAMA";
    Prefecture["TOTTORI"] = "TOTTORI";
    Prefecture["SHIMANE"] = "SHIMANE";
    Prefecture["OKAYAMA"] = "OKAYAMA";
    Prefecture["HIROSHIMA"] = "HIROSHIMA";
    Prefecture["YAMAGUCHI"] = "YAMAGUCHI";
    Prefecture["TOKUSHIMA"] = "TOKUSHIMA";
    Prefecture["KAGAWA"] = "KAGAWA";
    Prefecture["EHIME"] = "EHIME";
    Prefecture["KOCHI"] = "KOCHI";
    Prefecture["FUKUOKA"] = "FUKUOKA";
    Prefecture["SAGA"] = "SAGA";
    Prefecture["NAGASAKI"] = "NAGASAKI";
    Prefecture["KUMAMOTO"] = "KUMAMOTO";
    Prefecture["OITA"] = "OITA";
    Prefecture["MIYAZAKI"] = "MIYAZAKI";
    Prefecture["KAGOSHIMA"] = "KAGOSHIMA";
    Prefecture["OKINAWA"] = "OKINAWA";
})(Prefecture || (Prefecture = {}));
export var ShippingProductsRequirement;
(function(ShippingProductsRequirement) {
    ShippingProductsRequirement["FREEZER"] = "FREEZER";
    ShippingProductsRequirement["REFRIGERATOR"] = "REFRIGERATOR";
})(ShippingProductsRequirement || (ShippingProductsRequirement = {}));
export var ShippingProductsPriority;
(function(ShippingProductsPriority) {
    ShippingProductsPriority["TIME_TO_DELIVERY_TO_WAREHOUSE"] = "TIME_TO_DELIVERY_TO_WAREHOUSE";
    ShippingProductsPriority["TIME_TO_DELIVERY_TO_CUSTOMER"] = "TIME_TO_DELIVERY_TO_CUSTOMER";
})(ShippingProductsPriority || (ShippingProductsPriority = {}));
export var Region;
(function(Region) {
    Region["HOKKAIDO"] = "HOKKAIDO";
    Region["TOHOKU"] = "TOHOKU";
    Region["KANTO"] = "KANTO";
    Region["SHINETSU"] = "SHINETSU";
    Region["TOKAI"] = "TOKAI";
    Region["HOKURIKU"] = "HOKURIKU";
    Region["KANSAI"] = "KANSAI";
    Region["CHUGOKU"] = "CHUGOKU";
    Region["SHIKOKU"] = "SHIKOKU";
    Region["KYUSHU"] = "KYUSHU";
    Region["OKINAWA"] = "OKINAWA";
    Region["OTHER"] = "OTHER";
})(Region || (Region = {}));
export var WarehouseGroupStatus;
(function(WarehouseGroupStatus) {
    WarehouseGroupStatus["AVAILABLE"] = "AVAILABLE";
    WarehouseGroupStatus["UNAVAILABLE"] = "UNAVAILABLE";
})(WarehouseGroupStatus || (WarehouseGroupStatus = {}));
export var UserStatus;
(function(UserStatus) {
    UserStatus["AVAILABLE"] = "AVAILABLE";
    UserStatus["UNAVAILABLE"] = "UNAVAILABLE";
})(UserStatus || (UserStatus = {}));
export var BusinessStructureType;
(function(BusinessStructureType) {
    BusinessStructureType["SELF_EMPLOYMENT"] = "SELF_EMPLOYMENT";
    BusinessStructureType["COMPANY"] = "COMPANY";
})(BusinessStructureType || (BusinessStructureType = {}));
export var EcCartType;
(function(EcCartType) {
    EcCartType["SHOPIFY"] = "SHOPIFY";
    EcCartType["BASE"] = "BASE";
    EcCartType["STORES"] = "STORES";
    EcCartType["COLORMESHOP"] = "COLORMESHOP";
})(EcCartType || (EcCartType = {}));
export var EcMallType;
(function(EcMallType) {
    EcMallType["AMAZON"] = "AMAZON";
    EcMallType["RAKUTEN"] = "RAKUTEN";
    EcMallType["YAHOO"] = "YAHOO";
})(EcMallType || (EcMallType = {}));
export var ArrangementType;
(function(ArrangementType) {
    ArrangementType["TADAOKI"] = "TADAOKI";
    ArrangementType["SELF"] = "SELF";
})(ArrangementType || (ArrangementType = {}));
export var NekoposuPackingMaterialType;
(function(NekoposuPackingMaterialType) {
    NekoposuPackingMaterialType["ENVELOPE"] = "ENVELOPE";
    NekoposuPackingMaterialType["BOX_BRAWN"] = "BOX_BRAWN";
    NekoposuPackingMaterialType["BOX_WHITE"] = "BOX_WHITE";
})(NekoposuPackingMaterialType || (NekoposuPackingMaterialType = {}));
export var BoxPackingMaterialType;
(function(BoxPackingMaterialType) {
    BoxPackingMaterialType["BOX_BRAWN"] = "BOX_BRAWN";
    BoxPackingMaterialType["BOX_WHITE"] = "BOX_WHITE";
})(BoxPackingMaterialType || (BoxPackingMaterialType = {}));
export var CushioningMaterialType;
(function(CushioningMaterialType) {
    CushioningMaterialType["PAPER"] = "PAPER";
    CushioningMaterialType["BUBBLE_WRAP"] = "BUBBLE_WRAP";
    CushioningMaterialType["CUSHION"] = "CUSHION";
})(CushioningMaterialType || (CushioningMaterialType = {}));
export var ModelSortDirection;
(function(ModelSortDirection) {
    ModelSortDirection["ASC"] = "ASC";
    ModelSortDirection["DESC"] = "DESC";
})(ModelSortDirection || (ModelSortDirection = {}));
