import Head from 'next/head';
import React from 'react';
import Loading from '@presentation/components/loading/Loading';
import {useAuthState} from '@presentation/components/auth/AuthState';
import {SeoProps, SeoTags} from '../../common/seo/SeoTags';
import ScreenStateHandler from '../../common/screen/screenStateHandler/ScreenStateHandler';

export interface LayoutProps {
  children: React.ReactNode;
  seo: SeoProps;
}

const Layout: React.FC<LayoutProps> = ({
  children,
  seo,
}: LayoutProps): JSX.Element => {
  const {authState} = useAuthState();

  if (authState.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <SeoTags params={seo} />
      <ScreenStateHandler>{children}</ScreenStateHandler>
    </>
  );
};
export default Layout;
