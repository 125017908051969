import {atom} from 'recoil';
import {initialProfileInputs, ProfileInputsForForm} from './profile/inputs';
import {initialWarehouseInputs, WarehouseInputs} from './warehouse/inputs';
import {
  ConfirmationInputsForForm,
  initialConfirmationInputsForForm,
} from './confirmation/inputs';
import {
  Inputs as ShippingProductsInputs,
  defaultValues as shippingProductsDefaultValues,
} from '@presentation/components/shippingProducts/inputs';

export const Steps = {
  Initial: 'initial',
  Profile: 'profileInput',
  ShippingProducts: 'shippingProducts',
  Warehouse: 'warehouse',
  Confirmation: 'confirmation',
  Complete: 'complete',
} as const;
export type Steps = typeof Steps[keyof typeof Steps];

export type SignUpState = {
  step: Steps;
  inputs: {
    profile: ProfileInputsForForm;
    shippingProducts: ShippingProductsInputs;
    warehouse: WarehouseInputs;
    confirmation: ConfirmationInputsForForm;
  };
  // CognitoUser.sub
  userSub?: string;
  // Session Storage に保存した時刻
  storedAt?: Date;
};

export const initialSingUpState: SignUpState = {
  step: Steps.Initial,
  inputs: {
    profile: initialProfileInputs,
    shippingProducts: shippingProductsDefaultValues,
    warehouse: initialWarehouseInputs,
    confirmation: initialConfirmationInputsForForm,
  },
};

export const signUpStateKey = 'signUpStateKey';

export const signUpState = atom<SignUpState>({
  key: signUpStateKey,
  default: initialSingUpState,
});
