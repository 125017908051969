import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { y } from "@common/form/locale/ja_JP";
import { ShippingPercentageRegion } from "@common/graphql/API";
export var defaultValues = {
    productCategory: [],
    shippingQuantity: "",
    storageAreaSize: "",
    storageAreaSizeUnit: "",
    averageSalesPrice: "",
    sku: "",
    shippingPackageSizes: [],
    shippingPercentages: Object.values(ShippingPercentageRegion).map(function(region) {
        return {
            region: region,
            percentage: ""
        };
    }),
    manufacturingLocationPrefecture: "",
    requirements: [],
    priorities: [],
    customerUserId: ""
};
export var getValidationRules = function() {
    var basicRules = {
        shippingQuantity: y.number().required().integer().min(1).max(99999999).label("出荷件数"),
        storageAreaSize: y.number().required().integer().min(1).max(99999999).label("必要預りスペース"),
        storageAreaSizeUnit: y.mixed().required().test("isNotEmpty", "${path}を選択してください", function(input) {
            return input !== "";
        }).label("必要預りスペースの単位"),
        averageSalesPrice: y.number().required().integer().min(1).max(99999999).label("平均販売単価"),
        sku: y.number().required().integer().min(1).max(99999999).label("SKU数"),
        shippingPackageSizes: y.array().required().min(1).label("配送サイズ"),
        shippingPercentages: y.mixed().required().test("isNotEmpty", "${path}を入力してください", function(input) {
            if (input == null) {
                return false;
            }
            var empties = input.filter(function(item) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // 初期値が空文字列のため
                return item.percentage === "";
            });
            return empties.length !== input.length;
        }).test("isInt", "${path}は整数で入力してください", function(input) {
            if (input == null) {
                return false;
            }
            var notIntegers = input.filter(function(item) {
                // 入力欄から得られる値は文字列のため
                var number = Number(item.percentage);
                return !Number.isInteger(number);
            });
            return notIntegers.length === 0;
        }).test("totalEquals100", "${path}は合計で100%になるように入力してください", function(input) {
            if (input == null) {
                return false;
            }
            var total = input.reduce(function(prev, item) {
                // 入力欄から得られる値は文字列のため
                return prev + Number(item.percentage);
            }, 0);
            return total === 100;
        }).label("配送先の割合"),
        manufacturingLocationPrefecture: y.mixed().required("${path}を選択してください").test("isNotEmpty", "${path}を選択してください", function(input) {
            return input !== "";
        }).label("製造場所"),
        requirements: y.array().required().label("必要事項"),
        priorities: y.array().required().label("優先事項")
    };
    return y.object().shape(_object_spread({}, basicRules));
};
