import React from 'react';
import styles from './compare.module.scss';

const Compare: React.FC = (): JSX.Element => {
  return (
    <>
      <section
        id="js-l-compare"
        className={styles.lCompare + ' ' + styles.section}
      >
        <h2 className={styles.headline}>
          タダオキを使うと
          <br />
          <strong>
            <span className={styles.underLine}>梱包作業・発送作業が不要で</span>
            <span className={styles.wrapper}>
              <span className={styles.content}>\ おまけに /</span>
              <span className={styles.underLine}>コストが減る!</span>
            </span>
          </strong>
        </h2>
        <div className={styles.lCompareExample}>
          <h3>
            オリジナルT-シャツ、パーカーなどを取り扱うアパレルECショップの例
          </h3>
          <ul>
            <li>
              <p>商品：</p>
              <p>アパレル</p>
            </li>
            <li>
              <p>製造場所：</p>
              <p>埼玉県</p>
            </li>
            <li>
              <p>主要サイズ：</p>
              <p>60サイズ</p>
            </li>
            <li>
              <p>主な発送先：</p>
              <p>関東</p>
            </li>
            <li>
              <p>月の出荷量：</p>
              <p>200件</p>
            </li>
          </ul>
        </div>
        <div>
          <ol className={styles.lCompareFlow}>
            <CompareListCard isSelf />
            <CompareListCard isSelf={false} />
          </ol>
        </div>
      </section>
      <section className={styles.lCompareFooter + ' ' + styles.section}>
        <div className={styles.lCompareFooterTriangle}>
          <div className={styles.lCompareFooterTriangleWrapper}>
            <div
              className={
                styles.lCompareFooterTriangleContents + ' ' + styles.left
              }
            />
            <div
              className={
                styles.lCompareFooterTriangleContents + ' ' + styles.right
              }
            />
          </div>
        </div>
        <div className={styles.lCompareFooterContents}>
          <p className={styles.headline}>
            梱包作業・出荷作業が不要になり、送料・梱包資材費込みで&nbsp;
            <strong>¥36,000</strong>&nbsp;も安くなる！
          </p>
        </div>
      </section>
    </>
  );
};

type CompareListCardProps = {
  isSelf: boolean;
};

const CompareListCard = ({isSelf}: CompareListCardProps): JSX.Element => {
  const isSelfClassName = isSelf ? ' ' + styles.self : '';
  return (
    <li className={styles.listCard + isSelfClassName}>
      <div className={styles.lCompareFlowWrapper}>
        <div className={styles.lCompareFlowIconWrapper}>
          <div className={styles.lCompareFlowIcon + isSelfClassName}>
            <div className={styles.inner}>
              {isSelf ? (
                <h3 className={styles.self}>
                  自分で
                  <br />
                  発送作業
                </h3>
              ) : (
                <h3 className={styles.self}>
                  <svg
                    className={styles.self}
                    xmlns="http://www.w3.org/2000/svg"
                    width="90"
                    height="90"
                    viewBox="0 0 53.659 60.976"
                  >
                    <g
                      id="グループ_108"
                      data-name="グループ 108"
                      transform="translate(0 44.443)"
                    >
                      <path
                        id="パス_182"
                        data-name="パス 182"
                        d="M560.248,457.08a.42.42,0,0,1,.412.5q-.042.217-.083.413-.087.408-.1.443h5.537a.419.419,0,0,1,.419.418v2.239a12.717,12.717,0,0,1-1,4.133,9.657,9.657,0,0,1-1.977,2.926,13.714,13.714,0,0,1-2.4,1.928,13.263,13.263,0,0,1-2.282,1.155,13.615,13.615,0,0,1-1.724.547l-.172.038a.418.418,0,0,1-.507-.409v-1.981a.416.416,0,0,1,.317-.405l.293-.073a11,11,0,0,0,1.506-.53,10.5,10.5,0,0,0,1.907-1.068,8.139,8.139,0,0,0,1.829-1.78h-2.8a.419.419,0,0,1-.419-.418V463.4a.419.419,0,0,1,.419-.418h3.848a9.241,9.241,0,0,0,.331-1.962H559.5a12.083,12.083,0,0,1-2.09,2.961,17.483,17.483,0,0,1-1.655,1.433l-.014.01a.419.419,0,0,1-.665-.339V462.72a.417.417,0,0,1,.151-.321l.241-.2a6.093,6.093,0,0,0,.871-1,9.582,9.582,0,0,0,.958-1.693,10.076,10.076,0,0,0,.656-2.085.421.421,0,0,1,.411-.337Z"
                        transform="translate(-555.073 -455.309)"
                      />
                      <path
                        id="パス_183"
                        data-name="パス 183"
                        d="M627.844,450.38a.42.42,0,0,1,.412.5q-.042.217-.083.413-.087.408-.1.443h5.537a.419.419,0,0,1,.419.418v2.239a12.711,12.711,0,0,1-1,4.133,9.653,9.653,0,0,1-1.977,2.926,13.715,13.715,0,0,1-2.4,1.928,13.27,13.27,0,0,1-2.282,1.155,13.641,13.641,0,0,1-1.724.547l-.172.038a.418.418,0,0,1-.507-.409v-1.981a.416.416,0,0,1,.317-.405l.293-.073a11.01,11.01,0,0,0,1.507-.53,10.5,10.5,0,0,0,1.907-1.068,8.134,8.134,0,0,0,1.829-1.78h-2.8a.419.419,0,0,1-.419-.418V456.7a.419.419,0,0,1,.419-.418h3.848a9.242,9.242,0,0,0,.331-1.962h-4.093a12.076,12.076,0,0,1-2.09,2.961,17.483,17.483,0,0,1-1.655,1.433l-.014.01a.419.419,0,0,1-.665-.339V456.02a.417.417,0,0,1,.151-.321q.092-.077.241-.2a6.09,6.09,0,0,0,.871-1,9.58,9.58,0,0,0,.958-1.693,10.065,10.065,0,0,0,.656-2.085.421.421,0,0,1,.411-.337Zm3.115.311v-1.664a.419.419,0,0,1,.419-.418h.363a.419.419,0,0,1,.42.418v1.664a.419.419,0,0,1-.42.418h-.363A.419.419,0,0,1,630.959,450.691Zm1.881,0v-1.664a.419.419,0,0,1,.419-.418h.363a.419.419,0,0,1,.419.418v1.664a.419.419,0,0,1-.419.418h-.363A.419.419,0,0,1,632.84,450.691Z"
                        transform="translate(-608.493 -448.609)"
                      />
                      <path
                        id="パス_184"
                        data-name="パス 184"
                        d="M696.359,471.423v-7.153a13.494,13.494,0,0,1-2.046,3.378,16.994,16.994,0,0,1-2.247,2.24,12.768,12.768,0,0,1-1.759,1.233l-.088.047a.419.419,0,0,1-.618-.368v-2.192a.415.415,0,0,1,.2-.355l.343-.215a11.436,11.436,0,0,0,1.358-1.068,12.059,12.059,0,0,0,1.733-1.98,15.35,15.35,0,0,0,1.663-3.082H690.02a.419.419,0,0,1-.419-.418v-1.821a.419.419,0,0,1,.419-.418h6.338V457.5a.419.419,0,0,1,.419-.418h1.878a.419.419,0,0,1,.419.418v1.753h1.566a.419.419,0,0,1,.419.418v1.821a.419.419,0,0,1-.419.418h-1.566v9.515a.419.419,0,0,1-.419.418h-1.878A.419.419,0,0,1,696.359,471.423Z"
                        transform="translate(-661.387 -455.309)"
                      />
                      <path
                        id="パス_185"
                        data-name="パス 185"
                        d="M755.287,462.013v-1.821a.419.419,0,0,1,.419-.418h3.673l-.127-1.916a.419.419,0,0,1,.419-.446h1.949a.419.419,0,0,1,.418.388l.146,1.974h4.352a.419.419,0,0,1,.419.418v1.821a.419.419,0,0,1-.419.418h-4.178l.157,2.327h4.022a.419.419,0,0,1,.419.418v1.89a.419.419,0,0,1-.419.418h-3.812l.282,4.033a.419.419,0,0,1-.418.447h-1.963a.419.419,0,0,1-.418-.389l-.286-4.091h-4.213a.419.419,0,0,1-.419-.418v-1.89a.419.419,0,0,1,.419-.418h4.022l-.157-2.327h-3.865A.419.419,0,0,1,755.287,462.013Z"
                        transform="translate(-713.297 -455.571)"
                      />
                    </g>
                    <g
                      id="グループ_110"
                      data-name="グループ 110"
                      transform="translate(3.896)"
                    >
                      <path
                        id="パス_186"
                        data-name="パス 186"
                        d="M601.832,240.331l-11.966,2.731L577.9,254.107l18.413,10.6v14.7l23.931-13.776v-14.7Z"
                        transform="translate(-577.009 -239.442)"
                        fill="#fed900"
                      />
                      <path
                        id="パス_187"
                        data-name="パス 187"
                        d="M596.313,331.511l-18.413-10.6v-14.7l18.413,10.6Z"
                        transform="translate(-577.009 -291.545)"
                        fill="#d5ac00"
                      />
                      <path
                        id="パス_188"
                        data-name="パス 188"
                        d="M682.585,291.819v15.472l8.693-5.111V286.707Z"
                        transform="translate(-659.739 -276.12)"
                        fill="#fff"
                      />
                      <g id="グループ_109" data-name="グループ 109">
                        <path
                          id="パス_189"
                          data-name="パス 189"
                          d="M617.756,247.391c0-.014-.006-.027-.01-.041a.89.89,0,0,0-.058-.166h0a.9.9,0,0,0-.093-.148l-.028-.034a.884.884,0,0,0-.125-.125h0l-.007,0a.929.929,0,0,0-.1-.07l-18.413-10.6a.891.891,0,0,0-.645-.1l-11.966,2.731-.017.005c-.023.006-.045.013-.067.021s-.043.014-.064.023-.04.019-.059.029-.043.022-.064.035-.034.023-.051.035-.042.031-.061.048-.016.011-.023.018l-11.965,11.045-.013.014-.033.035c-.014.016-.029.031-.042.048l-.028.038c-.013.017-.024.035-.036.053l-.023.04c-.01.019-.02.038-.029.058s-.013.027-.018.041-.016.043-.022.065-.009.026-.012.04-.012.053-.016.08c0,.01,0,.019-.005.028a.861.861,0,0,0-.007.112v14.7a.888.888,0,0,0,.446.77l18.411,10.6h0a.894.894,0,0,0,.892,0l23.931-13.776a.889.889,0,0,0,.446-.77v-14.7h0A.885.885,0,0,0,617.756,247.391Zm-19.427-9.479,16.144,9.3-4.2.958-4.2.958v-2.459a.892.892,0,0,0-1.345-.766l-2.809,1.652-13.009-7.491Zm5.967,10.31V261.63l-6.909,4.063V252.284ZM586.629,240.8l13.532,7.792-4.119,2.422a.888.888,0,0,0-.439.766v5.911l-2.774,2.561-16.8-9.671Zm-11.2,11.488,8.664,4.989,7.965,4.586V274.5l-16.629-9.576ZM593.845,274.5V261.735l1.758-1.623v7.138a.892.892,0,0,0,1.345.766l8.693-5.111a.888.888,0,0,0,.439-.766V250.948l4.822-1.1,5.091-1.162v13.068Z"
                          transform="translate(-573.649 -236.078)"
                        />
                        <path
                          id="パス_190"
                          data-name="パス 190"
                          d="M693.106,314.192a.89.89,0,0,0,.452-.123l2.87-1.688a.89.89,0,1,0-.906-1.532l-2.87,1.688a.889.889,0,0,0,.454,1.655Z"
                          transform="translate(-667.347 -295.116)"
                        />
                        <path
                          id="パス_191"
                          data-name="パス 191"
                          d="M695.522,327.758l-2.87,1.688a.89.89,0,0,0,.906,1.532l2.87-1.688a.89.89,0,1,0-.906-1.532Z"
                          transform="translate(-667.348 -308.489)"
                        />
                        <path
                          id="パス_192"
                          data-name="パス 192"
                          d="M695.522,344.67l-2.87,1.688a.89.89,0,0,0,.906,1.532l2.87-1.688a.89.89,0,1,0-.906-1.532Z"
                          transform="translate(-667.348 -321.864)"
                        />
                      </g>
                    </g>
                  </svg>
                </h3>
              )}
            </div>
          </div>
        </div>
        <div className={styles.lCompareFlowDescription + isSelfClassName}>
          {isSelf ? (
            <>
              <h4>
                コンビニから発送する
                <br />
                <span>(コンビニ持ち込みなど)</span>
              </h4>
              <ul>
                <li>自分で梱包</li>
                <li>コンビニへ持ち込み</li>
              </ul>
            </>
          ) : (
            <>
              <h4>タダオキを利用</h4>
              <ul>
                <li>
                  <strong>出荷指示不要</strong>
                </li>
                <li>
                  <strong>梱包作業不要</strong>
                </li>
                <li>
                  <strong>送料・梱包資材費込み</strong>
                </li>
              </ul>
            </>
          )}
        </div>
      </div>
      <div className={styles.lCompareFlowPriceSummary}>
        {isSelf ? (
          <>
            <p className={styles.formula}>コンビニ発送(¥930) × 200件</p>
            <p className={styles.price}>
              ¥186,000<span>/月</span>
            </p>
          </>
        ) : (
          <>
            <p className={styles.formula}>60サイズ(¥750) x 200件</p>
            <p className={styles.price}>
              ¥150,000<span>/月</span>
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="121"
              height="132"
              viewBox="0 0 121 132"
            >
              <g id="badge" transform="translate(-254 -543)">
                <circle
                  id="bg"
                  cx="60.5"
                  cy="60.5"
                  r="60.5"
                  transform="translate(254 543)"
                  fill="#f08300"
                />
                <path
                  id="triangle"
                  d="M10,0,20,17H0Z"
                  transform="translate(325 675) rotate(180)"
                  fill="#f08300"
                />
                <text
                  id="送料込み_梱包_発送_作業不要"
                  data-name="送料込み
梱包・発送
作業不要"
                  transform="translate(315 586)"
                  fill="#fff"
                  fontSize="16"
                  fontFamily="HiraginoSans-W6, Hiragino Sans"
                >
                  <tspan x="-32" y="0">
                    送料込み
                  </tspan>
                  <tspan
                    y="0"
                    fontFamily="Helvetica-Bold, Helvetica"
                    fontWeight="700"
                  ></tspan>
                  <tspan x="-40" y="24">
                    梱包・発送
                  </tspan>
                  <tspan
                    y="24"
                    fontFamily="Helvetica-Bold, Helvetica"
                    fontWeight="700"
                  ></tspan>
                  <tspan x="-32" y="48">
                    作業不要
                  </tspan>
                </text>
              </g>
            </svg>
          </>
        )}
      </div>
    </li>
  );
};

export default Compare;
