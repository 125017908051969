import React from 'react';
import styles from './more.module.scss';
import clsx from 'clsx';

const More: React.FC = (): JSX.Element => {
  return (
    <section id="js-l-more" className={styles.lMore + ' ' + styles.section}>
      <div className={styles.lMoreMore}>
        <div className={styles.lMoreMoreLabel}>さらに!&nbsp;①</div>
        <h2 className={styles.headline}>
          タダオキなら
          <br />
          売上が大きくなっても
          <br className={styles.deviceTab} />
          シームレスに対応。
          <span>
            <strong>スペース移転の手続きは</strong>
            <br className={styles.deviceSp} />
            <strong>簡単!</strong>
          </span>
        </h2>
        <p>
          移転にかかるコストも初期費用もかからずOK!
          <br className={styles.devicePc} />
          システムも変わらないから今までどおりに使えて、規模がどんどん大きくなっても安心。
        </p>
        <div className={styles.lMoreMoreImages}>
          <img src="/images/lp/plan_more01@2x.png" alt="" />
          <img src="/images/lp/mv_img01@2x.png" alt="" />
        </div>
      </div>

      <div className={clsx(styles.lMoreMore, styles.lMoreMore2)}>
        <div className={styles.lMoreMoreLabel}>さらに!&nbsp;②</div>
        <h2 className={styles.headline}>
          タダオキなら
          <span className={styles.noMargin}>
            <strong>複数拠点で</strong>
            <br className={styles.deviceSp} />
            <strong>商品管理が可能です!</strong>
          </span>
        </h2>
        <div className={styles.lMoreMoreListCardWrapper}>
          <ul className={styles.clm2}>
            <li className={styles.lRecommendPerson + ' ' + styles.listCard}>
              <h3>1拠点でのお預かり</h3>
              <div>
                <h4 className={styles.headline}>
                  <strong>納品場所への送料が抑えられる</strong>
                </h4>
                <p>
                  製造場所、配送先地域のバランスの良いスペースをタダオキが自動的に選びます。納品場所が1箇所になりますので、納品にかかる送料を抑えることができます。
                  <br />
                  配送先地域によってはリードタイムがかかる場合があります。
                </p>
              </div>
            </li>
            <li className={styles.lRecommendProduct + ' ' + styles.listCard}>
              <h3>複数拠点でのお預かり</h3>
              <div>
                <h4 className={styles.headline}>
                  <strong>日本全国への配送リードタイムを最適化</strong>
                </h4>
                <p>
                  西日本1箇所、東日本1箇所など2拠点でお荷物を預かります。2拠点ありますので、配送先へのリードタイムを日本全国で最適化することができます。
                  <br />
                  <br />
                  <br />
                </p>
              </div>
            </li>
          </ul>
        </div>
        <p>
          ※複数拠点での商品管理をご希望の方はお申し込み後のチャットよりご要望をお伝えください。
        </p>
        <div className={styles.lMoreMoreImages2}>
          <img src="/images/lp/plan_more02@2x.png" alt="" />
        </div>
      </div>
    </section>
  );
};

export default More;
