import {ShippingPackageSize} from '@common/graphql/API';

export type FormInputs = {
  productCategory: [];
  shippingQuantity: number | '';
  shippingPercentages: {
    size: ShippingPackageSize;
    percentage: number | '';
  }[];
};

export const defaultValues: FormInputs = {
  productCategory: [],
  shippingQuantity: '',
  shippingPercentages: Object.values(ShippingPackageSize).map((size) => {
    return {
      size,
      percentage: '',
    };
  }),
};
