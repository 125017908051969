import _object_spread from "@swc/helpers/src/_object_spread.mjs";
export var sendEvent = function(event) {
    var parameters = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    // see: https://developers.google.com/tag-platform/devguides/datalayer?hl=ja
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.dataLayer.push(_object_spread({
        event: event
    }, parameters));
};
